.Login {
    font-family: 'didot', sans-serif;
    display: flex; 
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    color: white;
    background-color: #054b93;
    height: 100vh;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-submit-btn {
    margin-top: 10px;
    width: 100px;
    height: 30px;
    font-size: 18px;
    margin-bottom: 10px;
    cursor: pointer;
}

.create-account-btn {
    margin-left: 10px; 
    width: 150px; 
    height: 30px;
    font-size: 18px;
    cursor: pointer;
}