.MemoryForm {
    font-family: 'didot', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    margin-top: 3%;
}

.restaurant-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.asti-verbiage {
    text-align: center;
    width: 50vw;
}

.memory-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 18px;
    text-align: left;
}

.instructions {
    text-align: center;
}

.anonymous-note {
    padding: 0;
    margin: 20px 0 0 0;
    width: 50vw;
    text-align: left;
}

.memory-form-question {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 5px;
    margin-bottom: 5px;
}

.memory-form-question input {
    max-width: 300px;
}

.user-input-image {
    font-size: 16px;
}

.user-input-image::file-selector-button {
    background-color: darkred;
    color: white;
    width: 100px;
    height: 30px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    border: none;
}

.required-note {
    color: red;
    margin-left: 15px;
}

.submit-btn {
    background-color: darkred;
    color: white;
    width: 70px;
    height: 30px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 5px;
    border-radius: 5px;
    border: none;
}

@media screen and (max-width: 600px) {
    .MemoryForm {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        margin-top: 15%;
    }

    .memory-form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .memory-form-question {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 90vw;
    }
    .anonymous-note {
        text-align: center;
        width: 70vw;
    }
}