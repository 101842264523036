.DeleteMemories {
    font-family: 'didot', sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3%;
}

.delete-memories-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.admin-memory {
    border: 1px solid black;
    width: 300px;
    margin-bottom: 20px;
}


.admin-comments-container,
.admin-image-view {
    border: 1px solid black;
    z-index: 1;
    position: absolute;
    width: 300px;
    background: white;
    height: 400px;
    overflow-y: scroll;
}

.admin-image-view img {
    max-width: 300px;
    max-height: 400px;
}

.admin-comments-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style-type: none;
    padding-left: 5px;
    padding-right: 5px;

}

.admin-comments-list li {
    border: 1px solid black;
    width: 100%;
}

@media screen and (max-width: 600px) {
    section.delete-memories-grid {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}