@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;300;400&display=swap');

.Navbar {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: darkred;
    color: white;
    font-family: 'Noto Sans', sans-serif;
    width: 100%;
    height: 50px;
}

.nav-links {
    list-style-type: none;
    display: flex;
    justify-content: space-evenly;
    padding: 0 10px 0 10px;
    transition: all .2s ease-in-out;
    margin-top: 12px;
}

.nav-item-home {
    float: left;
    margin-left: 10px;
}

.nav-item {
    margin-bottom: 0 10px 0 10px;
}

a {
    color: white;
    font-size: 20px;
    text-decoration: none;
}

a:focus {
    text-decoration: underline;
}

.nav-item:hover {
    transform: scale(1.1, 1.1);
    border: 1px solid white;
}

.home-link {
    margin: 0;
}

@media screen and (max-width: 600px) {
    .Navbar {
        height: 75px;
        display: flex;
        justify-content: space-around;
    }

    .nav-links {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    .nav-links li {
        margin-left: 5px;
        margin-right: 5px;
    }
    
}
