@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

.Home {
    font-family: 'didot', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3%;
    color: black;
}

.asti-verbiage {
    font-size: 18px;
}

.nav-btn {
    width: 170px;
    height: 30px;
    font-size: 16px;
    background-color: darkred;
    color: white;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
    border: none;
    border-radius: 10px;
}

.asti-images {
    display: flex;
    justify-content: space-evenly;
}

.asti-img {
    max-height: 500px;
}

.asti-img, 
.asti-images-small {
    max-width: 400px;
}

.asti-images-small {
    display: grid;
    grid-template-columns: 1fr;
}

.asti-images-small img {
    max-width: 400px;
    max-height: 250px;
}

.asti-img-big {
    max-width: 550px;
    max-height: 500px;
}

.restaurant-info p {
    width: 70vw;
}

a.audio-link{
    color: darkred;
    font-size: 18px;
}

a.audio-link:hover {
    text-decoration: underline;
}

.audio-btn {
    background: darkred;
    color: white;
    width: auto;
    height: 30px;
    cursor: pointer;
    border-radius: 10px;
    font-family: 'Noto Sans', sans-serif;
    font-size: 18px;
    border: 1px solid darkred;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.audio-player {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.play-pause-btns {
    display: flex;
    justify-content: space-evenly;
}

.audio-verbiage {
    font-size: 18px;
    margin: 0;
    width: 75vw;
    text-align: center;
    margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
    .Home {
        margin-top: 15%;
    }
    .asti-images {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}