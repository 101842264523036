@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;300;400&display=swap');

.Memories {
    font-family: 'didot', 'sans-serif';
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3%;
    height: 100vh;
}

.memories-page-title {
    text-align: center;
    margin: 0;
}

.user-comments-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.sorting-btns {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
}

.sort-instructions {
    margin: 0;
    padding: 0;
}

.sort-btn {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
    font-size: 18px;
}

.memories-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.memory {
    border: 1px solid black;
    width: 400px;
    margin-bottom: 20px;
    color: black;
    margin-left: 5px;
}

.memory-prop {
    font-size: 18px;
}

h3.memory-prop {
    font-family: 'Noto Sans', sans-serif;
}

.pagination-container {
    display: flex;
    justify-content: space-evenly;
    list-style-type: none;
    cursor: pointer;
    padding: 0;
}

.pagination-container li:hover {
    text-decoration: underline;
}

.pagination-container .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: black;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 10px;
    line-height: 1.43;
    font-size: 18px;
    min-width: 32px;
}

.pagination-container .pagination-item.dots:hover {
    background-color: transparent;
    cursor: default;
}

.pagination-container .pagination-item.selected {
    background-color: darkred;
    color: white;
    text-decoration: none;
}

.pagination-container .pagination-item .arrow::before {
    position: relative;
    content: '';
    /* By using an em scale, the arrows will size with the font */
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    border-right: 0.12em solid darkred;
    border-top: 0.12em solid darkred;
}

.pagination-container .pagination-item .arrow.left {
    transform: rotate(-135deg) translate(-50%);
}

.pagination-container .pagination-item .arrow.right {
    transform: rotate(45deg);
}

.pagination-container .pagination-item .arrow:hover {
    text-decoration: underline;
}

.pagination-container .pagination-item.disabled {
    pointer-events: none;
}

.pagination-container .pagination-item.disabled .arrow::before {
    border-right: 0.12em solid rgba(0, 0, 0, 0.43);
    border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}

.pagination-container .pagination-item.disabled:hover {
    /* background-color: transparent; */
    cursor: default;
}

.textfield-container {
    display: flex;
    flex-direction: column;
}

.textfield-container p {
    margin: 0;
    font-size: 18px;
}

div.memory-search-field {
    /* TextField becomes a div on page render */
    border: 1px solid darkred;
    width: 250px;
}

div.memory-search-field input {
    color: darkred;
    border: 1px solid darkred;
    cursor: pointer;
    background-color: white;
}

div.memory-search-field input:hover {
    border-bottom: 1px solid darkred;
}

div.memory-search-field input::placeholder {
    color: darkred;
    opacity: 0.75;
}


.interaction-btn,
.sort-btn {
    background: darkred;
    color: white;
    width: auto;
    height: 30px;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 18px;
    border: 1px solid darkred;
}

.interaction-btn {
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 10px;
    font-size: 14px;
}

.like-btn:disabled,
.sort-btn:disabled {
    opacity: .75;
    cursor: default;
}

.comments-count {
    padding: 5px 0 0 0;
    margin: 5px 0 0 0;
}

.comments-container,
.image-view {
    border: 1px solid black;
    z-index: 1;
    position: absolute;
    max-width: 400px;
    min-width: 400px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 500px;
}

.comments-list {
    list-style-type: none;
    padding-left: 5px;
    padding-right: 5px;
    overflow-y: auto;
    flex: 1;
    width: 95%;
    margin-left: 5px;
    margin-right: 5px;
}

.comments-list-container,
.image-view {
    flex: 1;
    min-height: 0;
    overflow-y: auto;
    width: 100%;
}

.comments-list li {
    display: grid;
    grid-template-columns: 1fr;
    border: 1px solid black;
}

.comments-list li h4 {
    margin: 0;
    padding: 0;
    text-align: left;
}

.comment-text {
    margin: 0;
    padding: 0;
    font-size: 16px;
    text-align: left;
    max-width: 100%;
}

.new-comment {
    border-top: 1px solid black;
}

.new-comment, 
.comment-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    flex: 0 0 auto;
}

.new-comment input,
.new-comment button {
    width: 50%;
}

.image-view img {
    max-width: 375px;
    max-height: 400px;
}

@media screen and (max-width: 600px) {
    .Memories {
        margin-top: 15%;
    }

    section.memories-grid {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}