.Tribute {
    font-family: 'didot', sans-serif;
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tribute-text {
    max-width: 70vw;
    font-size: 18px;
}

.asti-tribute-header {
    width: 71vw;
}

.tribute-images {
    display: flex;
    flex-direction: column;
    border: 10px solid darkred;
}

.tribute-text-images {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    max-width: 80vw;
    gap: 0;
}

.tribute-back-columns {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    max-height: 700px;
}

.asti-tribute {
    width: 310px;
    max-height: 700px;
}

.asti-tribute-small {
    max-width: 400px;
    max-height: 233px;
}

@media screen and (max-width: 600px) {
    .Tribute {
        margin-top: 15%;
        text-align: center;
    }
    .asti-tribute {
        width: 80vw;
    }
    .tribute-images {
        display: grid;
        grid-template-columns: 1fr;
        width: 84vw;
    }
    .tribute-text-images {
        display: grid;
        grid-template-columns: 1fr;
        width: 80vw;
        gap: 0;
    }

    .asti-tribute-header {
        width: 80vw;
    }
}